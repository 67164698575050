import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ShoppingCartProvider } from "./components/_default/context/ShoppingCartContext"
import store from "./store/store"
import Redirect from "./components/_default/utilities/Redirect"
import TagManager from 'react-gtm-module'
// Pages
import Webpage1Varuosad from "./pages/Webpage1Varuosad";
import Webpage2K__psised from "./pages/Webpage2K__psised";
import Webpage3Lehte_ei_leitud from "./pages/Webpage3Lehte_ei_leitud";
import Webpage4Tellimus from "./pages/Webpage4Tellimus";
import Webpage5Toode from "./pages/Webpage5Toode";

var baseURL = 'https://laitserallypark.kassahaldus.ee/webstoreApi';
var axiosHeaders = {
    Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI1IiwianRpIjoiZGFhZGQxYzQzOWMxM2VlMWM1MjA1ODkxMGYzZDUwMzYwY2M4YzMzNTRlZWJlZGZlZmQzZWNkNGE2ZTUzNTU1ZWUwMjBjOWY0YjBlZjI3NjUiLCJpYXQiOjE3NDE2NzY5OTEuOTM0NzQzLCJuYmYiOjE3NDE2NzY5OTEuOTM0NzUzLCJleHAiOjE3NDE3NjMzOTEuOTI4OTcyLCJzdWIiOiI5MSIsInNjb3BlcyI6W119.AUbuLm3Mo2XUAQC7f_4Iz0eyQiuKONPGcyAj-pXqioJkGWtmJk99KalrIX3rex7A5DDFU9ifhdTcgfqFTXMywX9BnsE3JbUE8vEMXjVf6Z4ZrFU-mVqYsPVTRSf7EpS--4pInag0dbte2wC7-6mFbv2JsJuUfzk5TojdV8-NMi9DhhXn-LfwoTRiCiOWDjiHdZh0wbU61EAZey0IZNtoG1u9BSQMZAEaKMnev0V2Ikq-lateTfUzsgLS6dbXzHB012eGUD0glZm7GT-DdBwR4JQK10wOyOHKA4xLeJss5Hkeka4VRIWF2-KEvS6WeBSEZAOKy_PuRDYs39I_Qc4IDZKOGN7Kpn2CFT4in597Yc2nybvgGSoLupkvQGREWPxbYU_e0yel2q2Nj4BkJzF9TT5-gmpU4qaGvfR0dRVv1_3hX_Bv6Zz8PofAJVfWFsmdTjxbY5gsUB3jN0td3RCjiQ509WPdS3xx-m0Du6L1CVyUwe3FBfSuXGL0kTMNov5HcCOT7sUX2Z-8PAGc0KThh8jcwuRZQ1DQZDKvDj8prMu3Y6bhovHqoPwvgpbLyyrRK3caw78S-1wSYWRsj-nZZt_Dt03mnEUyMQp58iJEOG1wSDRNxo5FmLwpilZeO5N7Q5r-J8EN7yl28dCzwpZ2nHZ9an6SWYVAOPdL64RkJHc',
    'Access-api-key': "eyJpdiI6ImFEWjBtSVpNVi9iTVdDSnRlMkl0Znc9PSIsInZhbHVlIjoicm0zVFU2Mzhabm84RzRXdDNDS24zUT09IiwibWFjIjoiMTQ4NDRmZjcyY2ZiMDc4OTJkMDJjNzE2MmE5Y2ViOTcyMDAzY2Q1Y2VlZDA2ZTg2OGZhYzQ2YzEwZWI5NDZkNiIsInRhZyI6IiJ9",
    'Access-api-id': "eyJpdiI6IkpKL09RejVuM2NPVnZyY2N6cmlFdEE9PSIsInZhbHVlIjoidkpmTExVQXNKMktzMWxFQmhyWVNmQT09IiwibWFjIjoiNmYzOTIzYzE0YmZiYmE1MTc1MmRlYWI0NjE0MjRjZTkwNWQ1NzNmNTJjZjAzNDRmNGEyYWFjMTAxZWI1YjkxYSIsInRhZyI6IiJ9",
    'Access-Control-Allow-Origin': "*"
 };


window.axios = require('axios').create({
  baseURL: baseURL,
  headers: axiosHeaders
});
const tagManagerArgs = {
    gtmId: ""
}
TagManager.initialize(tagManagerArgs)
    
export default function App() {
    window.dataLayer.push({
        event: 'pageview'
    });
    return (
        <Provider store={store}>
            <ShoppingCartProvider>
                <BrowserRouter basename="/">
                    <Routes>
                                            <Route path="/et/et" element={<Webpage1Varuosad />} exact />;
                                                                    <Route path="/et/kupsised" element={<Webpage2K__psised />} exact />;
                                                                    <Route path="/et/*" element={<Webpage3Lehte_ei_leitud />} exact />;
                                                <Route path="*" element={<Webpage3Lehte_ei_leitud />} exact />;
                                                                    <Route path="/et/tellimus" element={<Webpage4Tellimus />} exact />;
                                                                    <Route path="/et/toode/:id" element={<Webpage5Toode />} exact />;
                                                                
                                                            </Routes>
                </BrowserRouter>
            </ShoppingCartProvider>
        </Provider>
    );
}